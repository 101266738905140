// GaTracker.js
const API_URL =
  process.env.REACT_APP_TRACKING_API_URL ||
  "https://metrics-collector.zappyride.com/track";
// Check if running on localhost
const isLocalhost = () => {
  const hostname = window.location.hostname;
  return hostname === 'localhost' || 
         hostname === '127.0.0.1' ||
         hostname.startsWith('192.168.') ||
         hostname.startsWith('10.') ||
         hostname.endsWith('.local');
};
// Get screen resolution utility
const getScreenResolution = () => {
  return `${window.screen.width}x${window.screen.height}`;
};

// Generate/retrieve client ID
const getClientId = () => {
  const storedState = localStorage.getItem('state');
  if (storedState) {
    const { uuid } = JSON.parse(storedState);
    if (uuid && uuid !== 'notfound') {
      localStorage.setItem('tracking_client_id', uuid);
      return uuid;
    }
  }
  
  let clientId = localStorage.getItem('tracking_client_id');
  if (!clientId) {
    clientId = crypto.randomUUID();
    localStorage.setItem('tracking_client_id', clientId);
  }
  return clientId;
};

// Fire and forget API call
const sendToApi = (type, data) => {
  // Skip tracking for localhost
  if (isLocalhost()) {
    console.log('Tracking skipped for localhost:', { type, data });
    return;
  }

  const payload = JSON.stringify({
    type,
    ...data,
    options: {
      ...data.options,
      ...getUTMParameters(), // Add UTM parameters to all events\
      postcode: data.options.postcode,
      title: document.title,
      url: window.location.href,
      referrer: document.referrer
    }
  });

  // Create headers
  const headers = {
    'Content-Type': 'application/json',
    'X-Client-ID': getClientId(), // Use provided userId or stored/generated client ID
    'X-Screen-Resolution': getScreenResolution(),
    'X-Request-ID': crypto.randomUUID(), // This can stay as random UUID since it's per-request
    'X-User-Agent': navigator.userAgent,
    'X-Accept-Language': navigator.languages ? navigator.languages.join(',') : navigator.language,
    'X-Referer': document.referrer
  };

  // if (navigator.sendBeacon) {
  //   const blob = new Blob([payload], { type: 'application/json' });
  //   navigator.sendBeacon(API_URL, blob);
  // } else {
    fetch(API_URL, {
      method: 'POST',
      headers,
      body: payload,
      keepalive: true,
      // mode: 'cors' ,
      // credentials: 'omit'
    }).catch(() => {
      // Intentionally ignore errors for fire and forget
    });
  }
// };

// Setup external link tracking
const setupLinkTracking = () => {
  document.addEventListener('click', (event) => {
    const link = event.target.closest('a');
    if (link && link.href) {
      const isExternal = link.hostname !== window.location.hostname;
      if (isExternal) {
        GaTracker.trackEvent(
          'outbound',
          'click',
          link.href,
          null,
          {
            link_domain: link.hostname,
            link_url: link.href,
            link_text: link.innerText || link.textContent
          }
        );
      }
    }
  });
};

const getUTMParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams = {};
  ['source', 'medium', 'campaign', 'term', 'content'].forEach(param => {
    const value = urlParams.get(`utm_${param}`);
    if (value) {
      utmParams[`utm_${param}`] = value;
    }
  });
  return utmParams;
};

// New: File download tracking
const setupFileDownloadTracking = () => {
  document.addEventListener('click', (event) => {
    const link = event.target.closest('a');
    if (link?.href) {
      // Common downloadable file extensions
      const fileExtensions = /\.(pdf|doc|docx|xls|xlsx|csv|zip|rar|txt)$/i;
      if (link.download || fileExtensions.test(link.href)) {
        GaTracker.trackEvent(
          'file',
          'download',
          link.href,
          null,
          {
            file_name: link.download || link.href.split('/').pop(),
            file_extension: link.href.split('.').pop().toLowerCase()
          }
        );
      }
    }
  });
};

// New: Performance monitoring
const trackPagePerformance = () => {
  if (window.performance) {
    // Wait for the load event to complete
    window.addEventListener('load', () => {
      setTimeout(() => {
        const performance = window.performance;
        const perfData = performance.timing;
        const pageLoadTime = perfData.loadEventEnd - perfData.navigationStart;
        const dnsTime = perfData.domainLookupEnd - perfData.domainLookupStart;
        const tcpTime = perfData.connectEnd - perfData.connectStart;
        const serverTime = perfData.responseEnd - perfData.requestStart;

        GaTracker.trackEvent(
          'performance',
          'timing',
          'page_load',
          Math.round(pageLoadTime),
          {
            dns_time: Math.round(dnsTime),
            tcp_time: Math.round(tcpTime),
            server_time: Math.round(serverTime),
            page_url: window.location.pathname
          }
        );
      }, 0);
    });
  }
};

const GaTracker = {
  
  initialize: () => {
    setupLinkTracking();
    setupFileDownloadTracking();
    trackPagePerformance();
  },
  trackPage: (page, options = {}) => {
    const formattedPage = page.length > 1 && page.charAt(page.length - 1) === "/" 
      ? page.substring(0, page.length - 1) 
      : page;
    
    sendToApi('page', {
      page: formattedPage,
      action: 'page_view',
      category: 'navigation',
      options: {
        ...options,
        path: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash
      }
    });
  },

  trackEvent: (category, action, label, value, options = {}) => {
    sendToApi('event', {
      category,
      action,
      label,
      value,
      options: {
        ...options,
        path: window.location.pathname,
        url: window.location.href
      }
    });
  }
};

export default GaTracker;