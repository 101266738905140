import React, { Component} from "react";
import "url-search-params-polyfill";
import { Switch, Route, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./Root.scss";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import PageLayout from "../../components/PageLayout/PageLayout"
import fetchWrapper from "../../../utils/fetch/fetchWrapper";

import banner from "../../assets/images/banner.jpg";
import eRidesBanner from "../../assets/images/eRides-banner.jpg"

import homeMetaImage from "../../assets/images/homepageMetaImage.jpg"
import calculatorMetaImage from "../../assets/images/calculator-preview.jpeg"
import csmMetaImage  from  "../../assets/images/csmMetaImage.png";
import dealersMetaImage  from  "../../assets/images/dealersMetaImage.png";
import evMetaImage  from  "../../assets/images/evMetaImage.png";
import faqMetaImage  from  "../../assets/images/faqMetaImage.png";
import incentivesMetaImage  from  "../../assets/images/incentivesMetaImage.png";
import inventoryMetaImage  from  "../../assets/images/inventoryMetaImage.png";




// Pages
import Home from "../../components/HomePage/Homepage";
import EVs from "../../../pages/EVs/EVs";
import EVsGraph from "../../../pages/EVs/EVsGraph"
import EV from "../../../pages/EV/EV";
import UsedEV from "../../../pages/UsedEV/UsedEV";
import UsedEVs from "../../../pages/UsedEVs/UsedEVs";
import Incentives from "../../../pages/Incentives/Incentives";
import MapPage from "../../../pages/MapPage/MapPage";
import DealerPage from "../../../client_customizations/components/DealerPage/DealerPage";
import FAQ from "../../../pages/FAQ/FAQ";
import ERides from "../../../pages/ERides/ERides"
import FourOhFour from "../../../pages/FourOhFour/FourOhFour";
import CompareVehicles from "../../../components/CompareVehicles/CompareVehicles"
import HomeChargers from "../../../pages/HomeChargers/HomeChargers"
import Electricians from "../../../pages/Electricians/Electricians"
import Inventory from "../../../pages/Inventory/Inventory"
import Custom from '../../../pages/Custom/Custom';

import SampleCustomPage from '../../../client_customizations/components/SampleCustomPage/SampleCustomPage'

// Services
import fetchElectricVehicles from "../../../services/fetchElectricVehicles";
import fetchGasolineVehicles from "../../../services/fetchGasolineVehicles"
import fetchIncentives from "../../../services/fetchIncentives";
import fetchVehicleIncentivesWithHandle from "../../../services/fetchVehicleIncentivesWithHandle";
import fetchHomeChargers from "../../../services/fetchHomeChargers"

// Utilities
import Uuid from "../../../utils/Uuid/Uuid";
import { loadState, persistState } from "../../../utils/LocalStorage/LocalStorage";
import loadUserPrefs from "../../../context/UserPrefs/loadUserPrefs";
import getUserPref from "../../../context/UserPrefs/getUserPref";
import USER_PREF_PRESETS from "../../../context/UserPrefs/USER_PREF_PRESETS";
import GaTracker from "../../../utils/GaTracker/GaTracker";
import isIE from "../../../utils/isIE";

class Root extends Component {
  constructor(props) {
    super(props);

    const existingState = loadState() || {};
    const savedPrefs =
      existingState && existingState.userPreferences
        ? existingState.userPreferences
        : {};

    if (!savedPrefs.vehicleFormFactorFilters)
      savedPrefs.vehicleFormFactorFilters = {};
    if (!savedPrefs.vehicleFuelTypeFilters)
      savedPrefs.vehicleFuelTypeFilters = {};
    if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};
    if (!savedPrefs.chargerFormFactorFilters)
      savedPrefs.chargerFormFactorFilters = {};
    if (!savedPrefs.chargerWifiFilter) savedPrefs.chargerWifiFilter = {};
    if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};
    if (!savedPrefs.inventoryDealers) savedPrefs.inventoryDealers = {};

    this.state = {
      uuid: existingState.uuid || Uuid(),
      ipData: existingState.ipData || null,
      chargingStations: null,
      electricVehicles: null,
      usedElectricVehicles: null,
      gasolineVehicles: null,
      incentives: null,
      incentivePrefsModalIsOpen: false,
      userLocation: null,
      userLocationNotFound: false,
      userLocationDealersNotFound: false,
      zipcodeUpdating: false,
      homeChargers: null,

      userPreferences: loadUserPrefs(savedPrefs),
    };
    // Last resort to ensure that the user has a UUID
    if (!this.state.uuid) this.state.uuid = Uuid();

    this.loadElectricVehicleData = this.loadElectricVehicleData.bind(this);
    this.loadGasolineVehicleData = this.loadGasolineVehicleData.bind(this);
    this.loadIncentivesData = this.loadIncentivesData.bind(this);
    this.updateUserPreferences = this.updateUserPreferences.bind(this);
    // this.loadChargingStations = this.loadChargingStations.bind(this);
    this.getUserZip = this.getUserZip.bind(this);
    this.incentivePrefsModalToggle = this.incentivePrefsModalToggle.bind(this);
    this.loadDealers = this.loadDealers.bind(this);
  }

  componentDidMount() {
    this.loadZipcodeData();
    // this.getUserZip();

    this.loadElectricVehicleData();
    this.loadGasolineVehicleData();
    this.loadIncentivesData();
    // this.loadChargingStations();
    this.loadDealers();
    this.loadHomeChargers();

    GaTracker.initialize();
    const page = this.props.location.pathname + this.props.location.search;
    GaTracker.trackPage(page, { userId: this.state.uuid });
  }

  // TODO: this should be cleaner
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.userPreferences.zipcode !==
        prevState.userPreferences.zipcode ||
      this.state.userPreferences.householdSize !==
        prevState.userPreferences.householdSize ||
      this.state.userPreferences.householdIncome !==
        prevState.userPreferences.householdIncome ||
      this.state.userPreferences.canTurnInClunker !==
        prevState.userPreferences.canTurnInClunker ||
      this.state.userPreferences.taxFilingStatus !==
        prevState.userPreferences.taxFilingStatus
    ) {
      this.loadElectricVehicleData();
      this.loadIncentivesData();
    } else if (
      this.state.userPreferences.vehicleIdForIncentives !==
        prevState.userPreferences.vehicleIdForIncentives ||
      this.state.userPreferences.vehicleHandleForIncentives !==
        prevState.userPreferences.vehicleHandleForIncentives
    ) {
      this.loadIncentivesData();
    }

    if (
      this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode
    ) {
      this.loadZipcodeData();
      this.loadDealers();
    }

    let prevZip = prevState.userLocation ? prevState.userLocation.zip : null;
    if (this.state.userLocation && this.state.userLocation.zip !== prevZip) {
      // this.loadChargingStations();
      this.loadDealers();
    }

    // if (this.state.userLocation !==
    //   prevState.userLocation) {
    //     this.loadChargingStations();
    // }

    if (!this.state.userLocationNotFound && !this.state.zipcodeUpdating) {
      persistState(this.state);
    }

    const currentPage = prevProps.location.pathname + prevProps.location.search;
    const nextPage = this.props.location.pathname + this.props.location.search;

    if (currentPage !== nextPage) {
      GaTracker.trackPage(nextPage, { userId: this.state.uuid });
    }

    if (this.props.language !== prevProps.language) {
      this.loadIncentivesData();
      this.loadElectricVehicleData();
    }
  }

  async loadElectricVehicleData() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      household_size: getUserPref("householdSize", this.state.userPreferences),
      household_income: getUserPref(
        "householdIncome",
        this.state.userPreferences
      ),
    };

    try {
      const electricVehicles = await fetchElectricVehicles(params);
      if (!electricVehicles) return;
      this.setState({
        electricVehicles: electricVehicles.newElectricVehicles,
        usedElectricVehicles: electricVehicles.usedElectricVehicles,
      });
    } catch (e) {
      // TODO: handle errors here
    }
  }

  async loadGasolineVehicleData() {
    let params = {
      fuel_type: "gas",
      postcode: getUserPref("zipcode", this.state.userPreferences),
    };

    try {
      const gasolineVehicles = await fetchGasolineVehicles(params);
      if (!gasolineVehicles) return;
      this.setState({ gasolineVehicles });
    } catch (e) {}
  }

  async loadIncentivesData() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      vehicle_handle: getUserPref(
        "vehicleHandleForIncentives",
        this.state.userPreferences
      ),
      household_size: getUserPref("householdSize", this.state.userPreferences),
      household_income: getUserPref(
        "householdIncome",
        this.state.userPreferences
      ),
      turn_in_clunker: getUserPref(
        "canTurnInClunker",
        this.state.userPreferences
      )
        ? true
        : null,
      lang: this.props.language === "EN" ? null : this.props.language,
    };

    try {
      const incentives =
        params["vehicle_handle"] !== ""
          ? await fetchVehicleIncentivesWithHandle(params)
          : await fetchIncentives(params);
      if (!incentives) return;
      this.setState({ incentives });
    } catch (e) {
      // TODO: handle error
    }
  }

  async loadHomeChargers() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
    };

    try {
      const homeChargers = await fetchHomeChargers(params);
      if (!homeChargers) return;
      this.setState({ homeChargers });
    } catch (e) {}
  }

  // loadChargingStations() {
  //   let zips =
  //     this.state.userLocation &&
  //       this.state.userLocation.nearby_postcodes &&
  //       this.state.userLocation.nearby_postcodes.length
  //       ? this.state.userLocation.nearby_postcodes.slice(1,200).join(",")
  //       : getUserPref("zipcode", this.state.userPreferences);
  //   let params = {
  //     fuel_type: "ELEC",
  //     format: "JSON",
  //     zip: zips,
  //     // NREL API doesn't handle preflight CORS requests properly, so we have to
  //     // include the API key as a GET query param instead of as a header
  //     api_key: process.env.REACT_APP_NREL_API_KEY
  //   };
  //   let url = new URL(
  //     "https://developer.nrel.gov/api/alt-fuel-stations/v1.json"
  //   );

  //   let searchParams = new URLSearchParams(params);

  //   url.search = searchParams;

  //   window.fetch(url, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json"
  //     }
  //   });

  //   window
  //     .fetch(url)
  //     .then(response => response.json())
  //     .then(data => {
  //       this.setState({ chargingStations: data.fuel_stations });
  //     });
  // }

  loadDealers() {
    if (!process.env.REACT_APP_PAGES_DEALERS_ENABLED) {
      return;
    }

    const params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      distance: isIE() ? 25 : 100,
    };
    let url = new URL(`${process.env.REACT_APP_API_HOST}/dealers`);

    let searchParams = new URLSearchParams(params);

    url.search = searchParams;

    fetchWrapper(url, {
      method: "GET",
    })
      .then((data) => {
        // Assuming fetchWrapper already returns the response JSON directly
        if (data.dealers) {
          this.setState({
            dealerLocations: data.dealers,
            userLocationDealersNotFound: false,
          });
        } else {
          this.setState({
            userLocationDealersNotFound: true,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  loadZipcodeData() {
    const params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      distance: isIE() ? 25 : 100,
    };
    let url = new URL(`${process.env.REACT_APP_API_HOST}/location`);

    let searchParams = new URLSearchParams(params);

    url.search = searchParams;

    fetchWrapper(url, {
      method: "GET",
    })
      .then((data) => {
        // Handle response logic
        if (data.location) {
          this.setState({
            userLocation: data.location,
            userLocationNotFound: false,
            zipcodeUpdating: false,
          });
          let newPrefs = {
            salesTax:
              data?.location?.regional_financial_references?.[0]?.sales_tax
                ?.region / 100.0,
            gasolinePriceInCentsPerGal:
              data.location?.regional_fuel_cost?.[0]?.gasoline * 100,
          };
          this.updateUserPreferences(newPrefs);
          this.incentivePrefsModalToggle(false);
        } else {
          // Handle case where location is not found
          this.setState({
            userLocationNotFound: true,
            zipcodeUpdating: false,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        this.setState({
          userLocationNotFound: true,
          zipcodeUpdating: false,
        });
      });
  }
  getUserZip() {
    let target = "post-code";
    var match = document.cookie.match(
      new RegExp("(^| )" + target + "=([^;]+)")
    );

    if (match) {
      return this.updateUserPreferences({ zipcode: match[2] });
    }
  }

  updateUserPreferences(newPrefs) {
    let prefs = Object.assign({}, this.state.userPreferences, newPrefs);
    let newState = {
      userPreferences: prefs,
    };
    if (
      newPrefs.zipcode &&
      this.state.userPreferences.zipcode !== newPrefs.zipcode
    )
      newState.zipcodeUpdating = true;
    this.setState(newState);
  }

  incentivePrefsModalToggle(override) {
    if (typeof override === "boolean") {
      this.setState({ incentivePrefsModalIsOpen: override });
    } else {
      this.setState({
        incentivePrefsModalIsOpen: !this.state.incentivePrefsModalIsOpen,
      });
    }
  }

  render() {
    const ip = this.state.ipData ? this.state.ipData.ip : null;
    const uuid = this.state.uuid;
    const language = this.props.language;
    const changeLanguage = this.props.changeLanguage;

    const userPrefs = {
      get: (key) => getUserPref(key, this.state.userPreferences),
      getPreset: (key) => USER_PREF_PRESETS[key],
      set: this.updateUserPreferences,
      zipcodeIsNotFound: this.state.userLocationNotFound,
      zipcodeIsUpdating: this.state.zipcodeUpdating,
      showIncentivePrefsModal: this.state.incentivePrefsModalIsOpen,
      toggleIncentivePrefsModal: this.incentivePrefsModalToggle,
      syncWorkingZipcode: () =>
        this.updateUserPreferences({
          zipcode: getUserPref("workingZipcode", this.state.userPreferences),
        }),
    };

    return (
      <UserPrefsContext.Provider value={userPrefs}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => {
              return (
                <PageLayout
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  ip={ip}
                  uuid={uuid}
                  description="Learn more about electric vehicles (EVs) incentives"
                  disclaimers="EV Savings Calculator provides illustrative estimations throughout and does not guarantee the accuracy of any costs, savings, hardware specifications, or incentives estimates. Please refer to referenced sources and original equipment manufacturers for up to date costs, hardware specifications, and incentives information."
                  page="Home | Austin Energy EV Buyer's Guide"
                  image={homeMetaImage}
                >
                  <Home
                    {...props}
                    electricVehicles={this.state.electricVehicles}
                    incentives={this.state.incentives}
                    userPreferences={this.state.userPreferences}
                    chargingStations={this.state.chargingStations}
                    userLocation={this.state.userLocation}
                    homePageBannerImage={banner}
                    ip={ip}
                    uuid={uuid}
                  />
                </PageLayout>
              );
            }}
          />
          {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
            <Route
              exact
              path="/faq"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    description="Answers to Frequently Asked Questions about electric vehicles (EVs) and their incentives"
                    page="FAQ | Austin Energy EV Buyer's Guide"
                    image={faqMetaImage}
                  >
                    <FAQ ip={ip} uuid={uuid} />;
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_ERIDES_ENABLED ? (
            <Route
              exact
              path="/e-ride"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    description="Answers to Frequently Asked Questions about electric vehicles (EVs) and their incentives"
                    page="ERides | Austin Energy Electric Ride Rebates"
                    image={faqMetaImage}
                  >
                    <ERides
                      ip={ip}
                      uuid={uuid}
                      eRidesBannerImage={eRidesBanner}
                      userLocation={this.state.userLocation}
                    />
                    ;
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (
            <Route
              exact
              path="/vehicles"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="Vehicles | Austin Energy EV Buyer's Guide"
                    ip={ip}
                    uuid={uuid}
                    description=""
                    image={calculatorMetaImage}
                  >
                    <EVs
                      {...props}
                      electricVehicles={this.state.electricVehicles}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
            <Route
              exact
              path="/used-vehicles"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="vehicles"
                    ip={ip}
                    e
                    uuid={uuid}
                  >
                    <UsedEVs
                      {...props}
                      usedElectricVehicles={this.state.usedElectricVehicles}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
            <Route
              path="/used-vehicles/:evId"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="vehicles"
                    ip={ip}
                    uuid={uuid}
                    disclaimers=""
                  >
                    <UsedEV
                      {...props}
                      chargingStations={this.state.chargingStations}
                      electricVehicles={this.state.usedElectricVehicles}
                      userLocation={this.state.userLocation}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (
            <Route
              exact
              path="/incentives"
              render={(props) => (
                <PageLayout
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  ip={ip}
                  uuid={uuid}
                  page="Incentives | Austin Energy EV Buyer's Guide"
                  description=""
                  disclaimers="Listed incentives may not be available at any given time. Listed incentives reflect an illustrative estimation of available incentives. {process.env.REACT_APP_FULL_COMPANY_NAME} does not recommend or endorse any particular automotive or insurance company."
                  image={incentivesMetaImage}
                >
                  <Incentives
                    {...props}
                    electricVehicles={this.state.electricVehicles}
                    incentives={this.state.incentives}
                    ip={ip}
                    uuid={uuid}
                  />
                </PageLayout>
              )}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (
            <Route
              exact
              path="/charging-stations"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    page="Charging Stations | Austin Energy EV Buyer's Guide"
                    description="Find local public charging stations"
                    image={csmMetaImage}
                  >
                    <MapPage
                      {...props}
                      chargingStations={this.state.chargingStations}
                      userLocation={this.state.userLocation}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_DEALERS_ENABLED ? (
            <Route
              exact
              path="/dealers"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    page="OEM Certrified Dealers | Austin Energy EV Buyer's Guide"
                    description="Find OEM certified Electric Vehicle (EV) dearlers"
                    image={dealersMetaImage}
                  >
                    <DealerPage
                      {...props}
                      dealerLocations={this.state.dealerLocations}
                      userLocation={this.state.userLocation}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_VEHICLES_MODEL_ENABLED ? (
            <Route
              path="/vehicles/:evId"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="Vehicles | Austin Energy EV Buyer's Guide"
                    ip={ip}
                    uuid={uuid}
                    description="Compare Electric Vehicles (EVs) by price, range and features."
                    disclaimers=""
                    image={evMetaImage}
                  >
                    <EV
                      {...props}
                      chargingStations={this.state.chargingStations}
                      electricVehicles={this.state.electricVehicles}
                      userLocation={this.state.userLocation}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (
            <Route
              exact
              path="/compare-vehicles"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="vehicles"
                    ip={ip}
                    uuid={uuid}
                  >
                    <CompareVehicles
                      props={props}
                      ip={this.state.ipData ? this.state.ipData.ip : null}
                      uuid={this.state.uuid}
                      electricVehicles={this.state.electricVehicles}
                      gasolineVehicles={this.state.gasolineVehicles}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_COMPARISON_GRAPH_ENABLED ? (
            <Route
              exact
              path="/comparison-graph"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    props={props}
                    page="cost vs range"
                    ip={ip}
                    uuid={uuid}
                  >
                    <EVsGraph
                      {...props}
                      electricVehicles={this.state.electricVehicles}
                      ip={ip}
                      uuid={uuid}
                      displayGraph={true}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (
            <Route
              exact
              path="/home-chargers"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    page="home chargers"
                  >
                    <HomeChargers
                      {...props}
                      homeChargers={this.state.homeChargers}
                      electricVehicles={this.state.electricVehicles}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_ELECTRICIANS_ENABLED ? (
            <Route
              exact
              path="/electricians"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    page="electricians"
                  >
                    <Electricians
                      {...props}
                      userLocation={this.state.userLocation}
                      ip={ip}
                      uuid={uuid}
                      zipcode={userPrefs.get("zipcode")}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_INVENTORY_ENABLED ? (
            <Route
              exact
              path="/inventory"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    props={props}
                    changeLanguage={changeLanguage}
                    ip={ip}
                    uuid={uuid}
                    page="Inventory | Austin Energy EV Buyer's Guide"
                    image={inventoryMetaImage}
                  >
                    <Inventory
                      {...props}
                      dealerLocations={this.state.dealerLocations}
                      userLocation={this.state.userLocation}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (
            <Route
              exact
              path={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
              render={(props) => {
                return (
                  <>
                    <Custom
                      {...props}
                      documentTitle={process.env.REACT_APP_PAGES_CUSTOM_1_TITLE}
                      PageContentFunction={() => {
                        return (
                          <SampleCustomPage
                            props={props}
                            ip={this.state.ipData ? this.state.ipData.ip : null}
                            uuid={this.state.uuid}
                          ></SampleCustomPage>
                        );
                      }}
                    />
                  </>
                );
              }}
            />
          ) : null}
          <Route component={FourOhFour} />
        </Switch>
      </UserPrefsContext.Provider>
    );
  }
}
    
    export default withRouter(Root);
